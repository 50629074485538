@import "./variables.less";

.cp_tab {
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .cp_tab_it {
    min-width: 5.625rem;
    height: 2.625rem;
    border-radius: .375rem;
    padding: 0 1.25rem;
    font-size: 14px;
    font-weight: 500;
    color: #6F6B7D;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #F2F1F5;
    }

    &.cp_tab_selected {
      background-color: @primary-color;
      color: #fff;
    }

    .cp_tab_icon {
      font-size: 20px;
      margin-right: .5rem;
    }
  }
}

.ant-modal-body {
  .ant-checkbox-wrapper {
    margin: 0 !important;

    span {
      color: #ea5455;
    }
  }
  .ant-checkbox-wrapper{
    margin: 1rem;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-input,
  .ant-checkbox:hover .ant-checkbox-checked {
    background-color: @primary-color;
    border-color: @primary-color;
    font-weight: 600;
  }


  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
    border-color: @primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-input {
    background-color: @primary-color;
    border-color: @primary-color;
  }

}

.foot_row {
  .foot_col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .foot_col:first-child {

    .ant-btn-default,
    .ant-btn-test {
      width: 88%;
      color: #fff;
      background: #ea5455;
      border-color: #ea5455;
    }
  }

  .foot_col:last-child {
    .ant-btn-default{
      width: 88%;
      color: #4b465c;
      background-color: #dfdfe3;
      border-color: #dfdfe3;
    }
  }
}

// 文件预览
.file_view {
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .fv_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    .anticon-close-circle {
      color: #fff;
      font-size: 2rem;
      position: absolute;
      top: 1rem;
      right: 2.5rem;
      cursor: pointer;
    }
  }
  .loading {
    font-size: 6.25rem;
    color: #FFFFFF;
  }
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12rem);
    height: calc(100% - 4rem);
  }
}

@media (max-width: 768px) {
  .file_view {
    display: none;
  }
}

// 文件上传
.cp_upload {
  .cp_upload_inner {
    padding: 1.25rem 0!important;
  }
  .ant-upload-text {
    font-size: 1.375rem!important;
    color: #333!important;
    font-weight: bold;
    margin: .625rem 0!important;
  }
  .upLoadic {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 3.125rem;
    }
  }
  .cp_card_des {
    color: #A5A3AE;
    margin: 1rem 0;
    font-size: 1rem;
  }
}

// 写作助手
.chat_helper {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 0 .625rem;
  // align-items: center;
  .chat_lt {
    display: flex;
    flex-wrap: wrap;
    max-height: 5rem;
    overflow: hidden;
    margin-bottom: -.375rem;
    .chat_helper_item {
      height: 2.125rem;
      padding: .3125rem .625rem .3125rem .375rem;
      margin-left: .375rem;
      display: flex;
      align-items: center;
      border-radius: .375rem;
      border: 1px solid #D8D8D8;
      margin-bottom: .375rem;
      user-select: none;
      position: relative;
      cursor: pointer;
      &:hover {
        color: @primary-color;
        border: 1px solid @primary-color;
        .chat_emoji {
          animation: emoji-swing 1s ease 1 !important;
          animation-fill-mode: forwards !important;
        }
        .chat_h_opt_box {
          z-index: 1;
          height: unset;
          .chat_h_star, .chat_h_setting {
            height: 1.375rem;
          }
        }
      }
      .chat_emoji {
        width: 1.5rem;
        height: 1.5rem;
        // line-height: 1.375rem;
        margin-right: .125rem;
        transform: scale(1.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .chat_h_opt_box {
      position: absolute;
      height: 0px;
      top: 0;
      right: 0;
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      border-radius: .25rem;
      background-color: @primary-color-hex6-18;
      .chat_h_star, .chat_h_setting {
        height: 0;
        width: 1.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &:hover {
          background-color: @primary-color-30;
        }
        .stared {
          color: #FFBD5A;
        }
      }
      .chat_h_setting_box {
        position: absolute;

      }
    }
  }
  .chat_gt {
    .chat_h_more_btn {
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      border-radius: 1.125rem;
      background: #FFBD5A;
      padding: 0 1rem;
      white-space: nowrap;
      cursor: pointer;
      .chat_h_more_icon {
        margin-right: .25rem;
      }
    }
  }
}
.chat_h_more_dropdown {
  width: 26rem;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  user-select: none;
  .chat_h_more_title {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
    color: #333333;
  }
  .chat_h_more_main {
    margin-left: -0.375rem;
    min-height: 10rem;
    max-height: 15rem;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
  }
  .chat_helper_d_item {
    height: 2.125rem;
    padding: .3125rem .625rem .3125rem .375rem;
    margin-left: .375rem;
    margin-top: .625rem;
    display: flex;
    align-items: center;
    border-radius: .375rem;
    border: 1px solid #D8D8D8;
    cursor: pointer;
    position: relative;
    &:hover {
      color: @primary-color;
      border: 1px solid @primary-color;
      .chat_emoji {
        animation: emoji-swing 1s ease 1 !important;
        animation-fill-mode: forwards !important;
      }
      .chat_h_opt_box {
        z-index: 1;
        height: unset;
        .chat_h_star, .chat_h_setting, .chat_h_edit {
          height: 1.375rem;
        }
      }
    }
    .chat_emoji {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.375rem;
      margin-right: .125rem;
      transform: scale(1.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chat_h_opt_box {
      position: absolute;
      height: 0px;
      top: 0;
      right: 0;
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      border-radius: .25rem;
      background-color: @primary-color-hex6-18;
      .chat_h_star, .chat_h_setting, .chat_h_edit {
        height: 0;
        width: 1.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &:hover {
          background-color: @primary-color-30;
        }
        .stared {
          color: #FFBD5A;
        }
      }
      .chat_h_setting_box {
        position: absolute;

      }
    }
  }
  .chat_h_more_option {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 1rem;
    top: 0;
    right: -100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all .3s;
    z-index: 1;
    &.show {
      width: 100%;
      right: 0;
    }
    .chm_header {
      margin-bottom: .625rem;
    }
    .opt_box {
      margin-top: .5rem;
      text-align: right;
      button + button {
        margin-left: .5rem;
      }
    }
  }
}
@keyframes emoji-swing {
  0% {
    transform: scale(1.2) rotate(0deg);
  }
  10% {
    transform: scale(1.4) rotate(10deg);
  }
  30% {
    transform: scale(1.5) rotate(-10deg);
  }
  50% {
    transform: scale(1.5) rotate(6deg);
  }
  70% {
    transform: scale(1.5) rotate(-4deg);
  }
  90% {
    transform: scale(1.5) rotate(2deg);
  }
  100% {
    transform: scale(1.5) rotate(0deg);
  }
}

@media ( max-width: 768px ) {
  .chat_helper {
    width: calc(100% - 2.625rem);
    margin: 0 1.5rem 0 1.25rem;
    position: absolute;
    top: .5rem;
    left: 0;
    transform: translate(0, -100%);
    background-color: #fff;
    .chat_lt {
      overflow: auto;
      max-height: 3.25rem;
      margin-bottom: -0.25rem;
      padding-top: .375rem;
      .chat_helper_item {
        height: 1.5rem;
        padding: 0 .625rem 0 .375rem;
        margin-left: .25rem;
        border-radius: .375rem;
        margin-bottom: 0.25rem;
        font-size: .875rem;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: @primary-color;
          border: 1px solid @primary-color;
          .chat_emoji {
            animation: emoji-swing 1s ease 1 !important;
            animation-fill-mode: forwards !important;
          }
        }
        .chat_emoji {
          width: 1.25rem;
          height: 1.25rem;
          // line-height: 1.375rem;
          margin-right: .125rem;
          transform: scale(1.2);
        }
      }
    }
    .chat_gt {
      .chat_h_more_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2.25rem;
        height: 2.25rem;
        padding: 0;
        cursor: pointer;
        .chat_h_more_icon {
          margin-right: 0;
        }
        .chat_h_more_btn_text {
          display: none;
        }
      }
    }
  }

  .chat_h_more_dropdown {
    width: 26rem;
    max-width: 96vw;
    display: flex;
    flex-direction: column;
    border-radius: 1.125rem;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    .chat_h_more_title {
      font-size: .875rem;
      color: #333333;
    }
    .chat_h_more_main {
      margin-left: -0.375rem;
      min-height: 10rem;
      max-height: 15rem;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      .chat_helper_d_item {
        height: 2.125rem;
        padding: .3125rem .625rem .3125rem .375rem;
        margin-left: .375rem;
        margin-top: .375rem;
        display: flex;
        align-items: center;
        border-radius: .375rem;
        border: 1px solid #D8D8D8;
        cursor: pointer;
        position: relative;
        &:hover {
          color: @primary-color;
          border: 1px solid @primary-color;
          .chat_emoji {
            animation: emoji-swing 1s ease 1 !important;
            animation-fill-mode: forwards !important;
          }
          .chat_h_opt_box {
            .chat_h_star {
              // height: 2.125rem;
            }
          }
        }
        .chat_emoji {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.375rem;
          margin-right: .125rem;
          transform: scale(1.2);
        }
        .chat_h_opt_box {
          .chat_h_star {
            // width: 2.125rem;
          }
        }
      }
    }
  }
}

// 懒加载
.cp_lazyimg {
  .cp_lazyimg_loading {
    background: rgba(255, 255, 255, 0.8);
    padding: .25rem;
    border-radius: .25rem;
    width: 5rem;
    height: 5rem;
    border: 1px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: .75rem;
    .anticon-loading {
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }
    .anticon-file-image {
      position: absolute;
      font-size: 32px;
      color: @primary-color-30;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .cp_lz_img {
    opacity: 1;
    transform: opacity .3s;
    &.cp_lz_v {
      position: absolute;
      max-width: 0;
      max-height: 0;
      opacity: 0;
    }
  }
}

.usage_box {
  display: flex;
  flex-direction: column;
  padding: 1.2rem .625rem;
  border-radius: .625rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  .header_usage {
    display: flex;
    line-height: 1.25;
    align-items: center;
    .hu_title {
      margin-right: .25rem;
    }
    .hu_text {
      color: rgba(255, 255, 255, 0.68);
      flex: 1;
      align-items: center;
    }
  }
  .header_divider {
    border-bottom: 1px solid #e8e8e8;
    margin: .875rem 0;
  }
  .header_type {
    line-height: 1.25;
    .ht_title {
      font-weight: 500;
    }
    .ht_select {
      margin-top: 0.5rem;
      display: flex;
      span {
        color: #FFFFFF !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .usage_box {
    padding: .625rem .5rem;
    border-radius: .5rem;
    min-width: 13.75rem;
    .header_usage {
      display: flex;
      line-height: 1.25;
      align-items: center;
      .hu_title {
        margin-right: .25rem;
      }
      .hu_text {
        color: rgba(255, 255, 255, 0.68);
        flex: 1;
        align-items: center;
      }
    }
    .header_divider {
      border-bottom: 1px solid #e8e8e8;
      margin: .5rem 0;
    }
    .header_type {
      line-height: 1.25;
      .ht_title {
        font-weight: 500;
      }
      .ht_select {
        margin-top: 0.5rem;
        display: flex;
        span {
          color: #FFFFFF !important;
        }
      }
    }
  }
}