@import "./variables.less";

.navigation {
  min-height: 100vh;
  .ant-layout-sider {
    height: 100vh;
    max-height: 100vh;
    box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    user-select: none;
    // 修改菜单样式
    .ant-menu {
      height: calc(100vh - 21rem);
      overflow: hidden;
      // 一级菜单间距
      &>* + * {
        margin-top: 1rem !important;
      }
      &.ant-menu-inline-collapsed {
        // 折叠后一级图标
        .ant-menu-item, .ant-menu-submenu-title {
          padding: 0 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-menu-item-icon {
            width: 1.5rem;
            min-width: 1.5rem;
            justify-content: center;
          }
          .ant-menu-title-content {
            display: none;
          }
        }
      }
      .ant-menu-submenu-title {
        margin: .125rem .875rem;
        width: calc(100% - 1.75rem);
        border-radius: .375rem !important;
        &:active , &:hover{
          // background: #f8f8f8 !important;
        }
      }
      .ant-menu-sub.ant-menu-inline {
        background: #111111 !important;
      }
      .ant-menu-item {
        margin: .125rem .875rem;
        width: calc(100% - 1.75rem);
        border-radius: .375rem !important;
        color: rgba(255, 255, 255, 0.5);
        &:active , &:hover{
          color: #FFFFFF !important;
          background: rgba(255, 255, 255, 0.1) !important;
        }
      }
      
      // 选中
      .ant-menu-submenu-selected {
        &>.ant-menu-submenu-title {
          // color: rgba(0,0,0,0.88) !important;
          font-weight: 600;
          // background: #f8f8f8 !important;
        }
        .ant-menu-item-selected {
          // background: linear-gradient(72.47deg,@primary-color 22.16%,@primary-color-70 76.47%) !important;
          // box-shadow: 0 2px 6px #7367f07a;
          // color: #fff !important;
        }
      }
      .ant-menu-item-selected {
        font-weight: 600;
        color: #FFFFFF !important;
        background: rgba(255, 255, 255, 0.1) !important;
      }
      .leadIc {
        font-size: 1.125rem;
      }
    }
    .logo_box {
      height: 5.125rem;
      margin: 0 .9375rem;
      // padding-right: 0.5rem;
      // padding-left: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon_box {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        .logo {
          width: 130px;
          max-width: 130px;
        }
      }
      .anticon {
        font-size: 1rem;
        color: rgba(255, 255, 255, .5);
      }
      .nav_close {
        cursor: pointer;
      }
      &.collapsed {
        max-width: 40px;
        width: fit-content;
        margin: 0 auto;
        padding: 0;
        // justify-content: center;
        .icon_box {
          min-width: 2.5rem;
          max-width: 2.5rem;
        }
        .nav_close {
          display: none;
        }
      }
    }
    .nav_create {
      width: calc(100% - 1.875rem);
      margin: 0 .9375rem 1.875rem;
      border: 0.0625rem solid #fff;
      border-radius: 0.625rem;
      height: 44px;
      background-image: linear-gradient(135deg,#854dff,#7062f2 100.000012%);
    }
  }
  .ant-layout {
    background: unset;
  }
  .usage_box {
    margin: 0 .9375rem;
    min-width: 14.375rem;
    &.collapsed {
      display: none;
    }
  }
  .nav_power {
    font-size: 1rem;
    margin-top: .5rem;
    color: rgba(255, 255, 255, .38);
    text-align: center;
    &.collapsed {
      display: none;
    }
  }
}

@media (max-width: 1200px)  {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: all .5s;
    &[open]{
      width: 100vw;
      background: #4b465c7f;
    }
    .ant-layout-sider {
      // 修改菜单样式
      .ant-menu {
        height: calc(100vh - 19rem);
      }
    }
  }
}