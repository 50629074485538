@import "./variables.less";

.app {
  background: #F8F7FA;
  height: 100vh;
  min-height: 100vh;
  .site-layout {
    max-height: 100vh;
    // overflow-x: hidden;
    overflow-y: auto;
    .app_content {
      padding: 1.5rem;
      width: 100%;
      max-width: 87rem;
      margin: 0 auto;
      &>*{
        padding-bottom: 1.25rem;
      }
      &>.cp_chat {
        border-radius: 0.375rem;
      }
    }
    .guide_content {
      padding: 1.25rem 1.5rem;
      width: 100%;
      // max-width: 87rem;
      margin: 0 auto;
      &>*{
        padding-bottom: 1.25rem;
      }
      &>.cp_chat {
        border-radius: 0.375rem;
      }
    }
  }
} 
