@import "./variables.less";

.header_bar_guide {
  position: sticky;
  top: 0;
  z-index: 1000;
  .ant-layout-header {
    height: 44px;
    // width: calc(100% - 3rem);
    // max-width: 84rem;
    margin:  1.25rem 1.5rem 0;
    border-radius: 0.375rem;
    padding: 0 0rem !important;
    background-color: transparent !important;
    // box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_lt {
      flex: 1;
      margin-right: 1.5rem;
      display: flex;
      align-items: center;
      .anticon {
        margin-right: .5rem;
      }
      .header_search {
        width: 100%;
      }
    }
    .header_gt {
      display: flex;
      align-items: center;
      .add_btn {
        margin-right: 1.5rem;
      }
      .header_language {
        cursor: pointer;
        margin-right: 1.5rem;
        .anticon {
          font-size: .75rem;
          margin-left: .25rem;
        }
      }
    }
  }
}